import { MdWhatsapp } from "react-icons/md";
import { SeeMore } from "../../components/SeeMore/SeeMore";
import { Button } from "../../components/Button/Button";
import * as S from "./Info.styles";

export const Info = () => {
  return (
    <S.InfoContainerWrapper id="info">
      <SeeMore element="about-me" />
      <S.InfoWrapper>
        <S.InfoHeaderWrapper>
          <h2>Se você sente ...</h2>
        </S.InfoHeaderWrapper>
        <S.InfoBodyWrapper>
          <ul>
            <li>
              Que o <strong>estresse</strong> está atrapalhando sua vida
            </li>
            <li>
              <p>
                Que a ansiedade e o medo está te <strong>impedindo</strong> de
                alcançar os seus objetivos
              </p>
            </li>
            <li>
              <strong>Dificuldade nos seus relacionamentos</strong> sociais,
              afetivos e/ou familiares
            </li>
            <li>
              Falta de <strong>autoconfiança</strong>
            </li>
            <li>
              Dificuldade para <strong>superar eventos</strong> e acontecimentos
              do passado
            </li>
          </ul>
          <p className="rodape">
            Meu trabalho é melhorar esses aspectos que podem estar atrapalhando
            sua vida e te afastando de uma existência mais leve!
            <br /> Vou te auxiliar nesse caminho ofertando um atendimento
            especializado para que você consiga superar esses desafios de forma
            segura e efetiva.
          </p>

          <Button
            text="Agendar consulta"
            icon={<MdWhatsapp size={28} />}
            color="#60644d"
            hovercolor="#888e6d"
          />
        </S.InfoBodyWrapper>
      </S.InfoWrapper>
    </S.InfoContainerWrapper>
  );
};
