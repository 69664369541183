import styled from "styled-components";

export const Button = styled.a<{
  color?: string;
  $hovercolor?: string;
  textColor?: string;
  $lowericon?: React.JSX.Element;
}>`
  position: static;
  background-color: ${(props) => (props.color ? props.color : "#60644d")};
  color: #60644d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: white;
  padding: 0px;
  padding-top: ${(props) => (props.$lowericon ? "5px" : "0px;")};
  width: ${(props) => (props.$lowericon ? "75px" : "200px;")};
  border-radius: ${(props) => (props.$lowericon ? "25px" : "5px;")};
  height: 40px;
  min-height: 40px;
  font-size: 16px;
  font-weight: 400;
  gap: ${(props) => (props.$lowericon ? "0px" : "10px;")};
  margin-top: 15px;
  flex-direction: ${(props) => (props.$lowericon ? "column" : "row")};

  p {
    font-size: 18px;
    font-weight: 400;
    font-family: "corporative";
    color: ${(props) => (props.textColor ? props.textColor : "white")};
    margin-bottom: 3px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.$hovercolor ? props.$hovercolor : "#888e6d"};
    box-shadow: 2px 2px 5px white;
  }
  @media (min-width: 768px) {
    width: ${(props) => (props.color === "var(--primary)" ? "100px" : "310px")};
    height: 50px;
    font-size: 24px;
    gap: ${(props) => (props.$lowericon ? "0px" : "10px")};
    margin-top: 16px;
    p {
      font-size: 24px;
      font-weight: 400;
      font-family: "corporative";
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
