import styled from "styled-components";

export const DrawerContent = styled.div<{ $isopen: string }>`
  display: ${(props) => (props.$isopen === "true" ? "flex" : "none")};
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #e0b88b;
  h4 {
    padding: 20px;
    color: white;
    font-size: 15px;
    font-family: "colabero";
    color: var(--primary);
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
