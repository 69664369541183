import styled from "styled-components";

export const InfoContainerWrapper = styled.div`
  background-color: #fffcf9;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px;
  padding-top: 10px;
  position: relative;
  box-shadown-top: none;
  width: 100%;
  align-items: center;
`;

export const InfoHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #979e7e;
  border-radius: 25px 25px 0px 0px;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
  text-align: start;
  padding-left: 24px;
  h2 {
    font-size: 16px;
    font-weight: 900;
    color: white;
    margin-bottom: 1rem;
    font-family: colabero;
  }
  @media (min-width: 500px) {
    h2 {
      font-size: 20px;
    }
  }
  @media (min-width: 720px) {
    h2 {
      font-size: 24px;
    }
  }
  @media (min-width: 1024px) {
    h2 {
      font-size: 32px;
    }
  }
`;

export const InfoBodyWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  ul {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
    text-align: left;
    @media (min-width: 1444px) {
      margin-left: 5%;
      align-self: start;
    }
    li {
      color: #60644d;
      font-size: 18px;
      font-family: "corporative";
      margin-bottom: 10px;

      @media (min-width: 720px) {
        font-size: 25px;
      }
      @media (min-width: 1024px) {
        font-size: 30px;
      }
      strong {
        font-family: "corporative-medium";
      }
    }
  }
  .rodape {
    color: #60644d;
    font-size: 16px;
    padding: 15px;
    text-align: left;

    @media (min-width: 1024px) {
      padding: 32px;
      padding-top: 20px;
      font-size: 20px;
    }

    @media (min-width: 720px) {
      padding: 24px;
      padding-top: 16px;
      font-size: 24px;
    }

    @media (min-width: 1024px) {
      padding: 32px;
      padding-top: 20px;
      font-size: 30px;
    }
  }

  @media (min-width: 1024px) {
    padding: 32px;
    padding-top: 20px;
  }

  @media (min-width: 720px) {
    padding: 24px;
    padding-top: 16px;
  }

  @media (min-width: 1024px) {
    padding: 32px;
    padding-top: 20px;
  }
`;

export const WhatsAppButton = styled.a`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background-color: var(--alternative);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  gap: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const ColoredLine = styled.div`
  width: 80%;
  height: 2px;
  background: linear-gradient(
    135deg,
    rgba(224, 184, 139, 1) 15%,
    rgba(130, 68, 39, 1) 52%,
    rgba(135, 171, 152, 1) 100%
  );
  position: absolute;
  zindex: -1;
  top: 9%;
`;

export const Button = styled.a`
  align-self: end;
  position: static;
  border-radius: 5px;
  background-color: #824427;
  color: #60644d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: white;
  padding: 0px;
  width: 175px;
  height: 40px;
  font-size: 12px;
  gap: 10px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    background-color: #888e6d;
    box-shadow: 2px 2px 5px white;
  }
  @media (min-width: 768px) {
    width: 250px;
    height: 50px;
    font-size: 16px;
    gap: 10px;
    margin-top: 16px;
  }
`;

export const InfoWrapper = styled.div`
  width: 87vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #979e7e;
  border-radius: 25px 25px 50px 50px;
  margin-top: 10px;
  padding-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (min-width: 768px) {
    width: 70vw;
  }
`;
