import { useRef } from "react";
import { MdWhatsapp } from "react-icons/md";
import * as S from "./AboutMe.styles";
import { useIsVisible } from "../../utils/useVisible";
import aboutMe from "../../images/aurea_photo.jpg";
import { BodyText } from "../../components/BodyText/BodyText";
import { Button } from "../../components/Button/Button";
import { SubTitleText } from "../../components/SubTitleText/SubTitleText";

export const AboutMe = () => {
  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useIsVisible(ref);

  return (
    <>
      <S.AboutMeWrapper id="about-me">
        <S.AboutMeTopDivider />
        <S.AboutMeImage
          src={aboutMe}
          alt="Aurea Logo Branca"
          width={380}
          height={500}
        />

        <S.AboutMeTextWrapper ref={ref} $applyanimation={isVisible.toString()}>
          <SubTitleText text="Sobre mim" />
          <BodyText>
            <p>
              Olá, me chamo Áurea Gomes, sou psicóloga clinica CRP 15-7553,
              formada pela Universidade Federal de Alagoas (UFAL) e pós
              graduanda em Neuropsicologia e Terapia
              Cognitivo-Comportamental(TCC).
              <br /> Realizo atendimentos de forma online e presencial em
              Maceió/AL.
              <br />
              <br />
              Atuo com adultos e adolescentes utilizando a TCC, que relaciona
              nossos pensamentos, comportamentos e sentimentos.
              <br />
              <br />
              <q>
                <i>
                  A Terapia Cognitivo-Comportamental nos ensina que, ao
                  modificar nossos pensamentos, podemos transformar nossas
                  emoções e comportamentos.
                </i>
              </q>
              <br />
              <br />- Judith Beck
            </p>
          </BodyText>
          <Button icon={<MdWhatsapp color="white" size={28} />} />
        </S.AboutMeTextWrapper>
        <S.AboutMeBottomDivider />
      </S.AboutMeWrapper>
    </>
  );
};
