"use client";

import * as S from "./page.styles";
import { Banner } from "./section/Banner/Banner";
import { AboutMe } from "./section/AboutMe/AboutMe";
import { Services } from "./section/Services/Services";
import { Info } from "./section/Info/Info";
import { useEffect, useState } from "react";
import { MdWhatsapp } from "react-icons/md";
import ReactGA from "react-ga4";
import { GoogleTagManagerProvider } from "@tracktor/react-google-tag-manager";
import { Header } from "./section/Header/Header";
import { Footer } from "./section/Footer/Footer";
import { FAQ } from "./section/FAQ/FAQ";
import { Drawer } from "./components/Drawer/Drawer";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    setInterval(() => {
      if (process.env.REACT_APP_GA_ID) {
        ReactGA.initialize(process.env.REACT_APP_GA_ID);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div />;
  }

  return (
    <GoogleTagManagerProvider id={process.env.REACT_APP_GTM_ID}>
      <S.Container>
        <meta name="keywords" content="Psicologa, Áure Gomes, Terapia, TCC" />
        <meta name="title" content="Psicóloga Áurea Gomes" />
        <meta
          name="description"
          content="Psicologa clinica, atuando com teoria cognitivo comportamental CRP 15-7553, atendimento presencial e online em Maceió/AL."
        />
        <Drawer isopen={isDrawerOpen} onCloseClick={toggleDrawer} />
        <Header onDrawerClick={toggleDrawer} />
        <Banner />
        <Info />
        <AboutMe />
        <Services />
        <FAQ />
        <Footer />
        <S.WhatsAppWrapper>
          <a target="_blank" href="https://wa.link/netv6h" rel="noreferrer">
            <MdWhatsapp color="white" />
          </a>
        </S.WhatsAppWrapper>
      </S.Container>
    </GoogleTagManagerProvider>
  );
}
