import styled from "styled-components";

export const BannerWrapper = styled.div<{ $ismobile: string }>`
  margin-top: ${(props) => (props.$ismobile === "true" ? "80px" : "0px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  z-index: 1;

  min-height: 400px;
  background: #979e7e;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;

  position: relative;

  @media (min-width: 768px) {
    height: 75vh;
    display: flex;
    height: 80vh;
    background: #979e7e;
    justify-content: end;
    width: 100%;
  }
`;

export const LeftContent = styled.div`
  z-index: 2;
  display: flex;
  position: absolute;
  left: 0;
  flex-direction: column;
  align-self: center;
  gap: 12px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 32px;
  margin-bottom: 32px;

  @min-height: 768px {
    gap: 24px;
  }

  @media (min-width: 768px) {
    margin-left: 0px;
    position: absolut;
    background: #979e7e;
    justify-content: center;
    gap: 20px;
    height: 100%;
    width: 60vw;
    padding: 16px;
    border-radius: 0% 10% 10% 0%;
    padding-left: 64px;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  display: none;

  @media (min-width: 768px) {
    width: 50vw;
    display: flex;
    height: 80vh;
    object-fit: cover;
    display: flex;
  }
`;

export const BannerTitle = styled.h3`
  position: relative;
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 32px;
  font-weight: 500;
  margin-right: 5vw;
  font-style: italic;

  @media (min-width: 768px) {
    font-size: 40px;
    font-weight: 500;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
    font-weight: 500;
  }
  @media (min-width: 1280px) {
    font-size: 60px;
    font-weight: 500;
  }
`;

export const BannerSubTitle = styled.h3`
  position: relative;
  color: black;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const BannerBody = styled.span`
  position: relative;
  color: black;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 20px;
    margin-right: 5vw;
  }
`;

export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const BannerButton = styled.a`
  position: static;
  border-radius: 5px;
  background-color: #60644d;
  color: #60644d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: white;
  padding: 0px;
  width: 175px;
  height: 40px;
  font-size: 12px;
  gap: 10px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    background-color: #888e6d;
    box-shadow: 2px 2px 5px white;
  }
  @media (min-width: 768px) {
    width: 250px;
    height: 50px;
    font-size: 16px;
    gap: 10px;
    margin-top: 16px;
  }
`;

export const ImageWrapper = styled.div`
  display: none;
  background: #f5dec7;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 0px;

  position: relative;
  .imageBanner {
    width: 100%;
    display: none;
  }
  .imageBannerLogo {
    display: none;
  }

  @media (min-width: 768px) {
    display: flex;
    margin-right: 70px;

    .imageBanner {
      width: 100%;
      display: flex;
      width: 400px;
      height: 450px;
      min-width: 400px;
    }
    .imageBannerLogo {
      display: flex;
    }
  }
`;
