import { RiCloseFill } from "react-icons/ri";
import { DrawerContent } from "./Drawer.styles";
import { scrollToDivElement } from "../../utils/utils";

type DrawerProps = {
  isopen: boolean;
  onCloseClick: () => void;
};

export const Drawer = ({ isopen, onCloseClick }: DrawerProps) => (
  <DrawerContent $isopen={isopen.toString()}>
    <RiCloseFill
      style={{ position: "absolute", right: "5%", top: "5%" }}
      color="var(--primary)"
      size={32}
      onClick={onCloseClick}
    />
    <h4
      onClick={() => {
        scrollToDivElement("info");
        onCloseClick();
      }}
    >
      Como Funciona?
    </h4>
    <h4
      onClick={() => {
        scrollToDivElement("about-me");
        onCloseClick();
      }}
    >
      Sobre Mim
    </h4>
    <h4
      onClick={() => {
        scrollToDivElement("services");
        onCloseClick();
      }}
    >
      Serviços
    </h4>
    <h4
      onClick={() => {
        scrollToDivElement("FAQ");
        onCloseClick();
      }}
    >
      FAQ
    </h4>
  </DrawerContent>
);
