import React from "react";
import * as S from "./Button.styles";

type ButtonProps = {
  text?: string;
  color?: string;
  textColor?: string;
  hovercolor?: string;
  icon?: React.JSX.Element;
  lowericon?: React.JSX.Element;
};

export const Button = ({
  text,
  color,
  hovercolor,
  icon,
  lowericon,
  textColor,
}: ButtonProps) => {
  return (
    <S.Button
      target="_blank"
      href={lowericon ? undefined : "https://wa.link/netv6h"}
      color={color}
      $hovercolor={hovercolor}
      $lowericon={lowericon}
      textColor={textColor}
    >
      {icon}
      <p>{text ? text : "Agendar consulta"}</p>
      {lowericon}
    </S.Button>
  );
};
