import { BodyText } from "../../components/BodyText/BodyText";
import { Button } from "../../components/Button/Button";
import * as S from "./Services.styles";

export const Services = () => {
  return (
    <S.ServicesWrapper id="services">
      <S.ServiceTitle>Serviços</S.ServiceTitle>
      <S.CardsWrapper>
        <S.Card>
          <S.CardHeader>
            <h4>Terapia Individual</h4>
          </S.CardHeader>
          <BodyText>
            <p>
              A terapia individual é um espaço seguro e acolhedor para você se
              conhecer melhor, superar desafios emocionais e desenvolver uma
              vida mais equilibrada.
            </p>
          </BodyText>
          <Button color="var(--primary)" text="Consulte valores" />
        </S.Card>
        <S.Card>
          <S.CardHeader>
            <h4>Terapia Casal</h4>
          </S.CardHeader>
          <BodyText>
            <p>
              A terapia de casal fortalece a relação, melhora a comunicação,
              auxilia na resolução de conflitos e promove um relacionamento mais
              saudável.
            </p>
          </BodyText>
          <Button color="var(--primary)" text="Consulte valores" />
        </S.Card>
      </S.CardsWrapper>
    </S.ServicesWrapper>
  );
};
