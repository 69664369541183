import { BsList } from "react-icons/bs";
import * as S from "./Header.styles";
import logo from "../../images/logo_1.webp";
import { scrollToDivElement } from "../../utils/utils";

type HeaderProps = {
  onDrawerClick: () => void;
};
export const Header = ({ onDrawerClick }: HeaderProps) => {
  const isMobile = window.innerWidth < 760;
  return (
    <S.HeaderWrapper $ismobile={isMobile.toString()}>
      {isMobile && (
        <BsList
          color="var(--primary)"
          size={32}
          style={{ position: "absolute", left: "5%" }}
          onClick={onDrawerClick}
        />
      )}
      <S.HeaderImage
        src={logo}
        alt="Aurea Logo"
        width={150}
        height={150}
        loading="eager"
      />
      <S.HeaderButtonsWrapper>
        <h4 onClick={() => scrollToDivElement("info")}>Como Funciona?</h4>
        <h4 onClick={() => scrollToDivElement("about-me")}>Sobre Mim</h4>
        <h4 onClick={() => scrollToDivElement("services")}>Serviços</h4>
        <h4 onClick={() => scrollToDivElement("FAQ")}>FAQ</h4>
      </S.HeaderButtonsWrapper>
    </S.HeaderWrapper>
  );
};
